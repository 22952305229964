
interface SiginInResponse { tokenUser: string, status: boolean, message: string, isConnectOtherExist: boolean, platforms: any, accountId: string, companyName: string }
interface PayloadGetToken { code: string, state: string, platform?: string, updateToken?: string }
const MiraklLocalKey = 'mirakl-platform';

import axios from '@/plugins/axios'
import baseRequest from 'axios'
import { Options, Vue } from "vue-class-component";
import { ElNotification } from 'element-plus'
import MiraklButton from '@/components/MiraklButton.vue';
@Options({
  components: {
    MiraklButton
  }
})
export default class SsoPage extends Vue {
  imageUrl = process.env.VUE_APP_BASE_URL;
  skipedSave = false
  cancelTokenSource = baseRequest.CancelToken.source()
  fullscreenLoading = false
  isCallback = false
  showSiginInWithMirakl = true
  dataConnect: any = {}
  options: any
  visible = false
  tokenUser = ''
  accountId = ''
  tokenTypes = [
    //woocommerce
    'consumerKey',
    'consumerSecret',

    //CA

    // bigcommerce    
    'storeHash',
    'bigcommerceToken',
    'clientId',

    // quickbook

    // Veeqo
    'apikey', // linnworks

    // Magento 2
    'magentoConsumerKey',
    'magentoConsumerSecret',
    'magento_access_token',
    'magentoTokenSecret',

    // Magento 1
    'magento1_key',
    'magento1_secret',

    // Teapplix
    'teapplix_api_token',

    // Mirakl
    'mirakl_client_id',
    'mirakl_client_secret',
    'mirakl_company_id',

    // Shopify

    // peoplevox
    'peoplevoxPassword',

    // prestashop
    'prestashopKey',

    // visualsoft
    'visualsoftPassword',

    // lightspeed
    'lightspeedClientSecret',

    // lightspeedX
    'lightspeedX_token',

    // Tiktok

    // alphabroder
    'alphabroderPassword',
    'alphabroderFTPPassword',
    'alphabroderPublicPassword',

    // brightpearl

    // Odoo
    'odoo_password',

    // Essendant
    'essendantPassword',

    // Xentral
    'xentralToken',

    // NetSuite
    'netsuiteConsumerKey',
    'netsuiteConsumerSecret',
    'netsuiteTokenId',
    'netsuiteTokenSecret',

    // Xero
    'xero_client_secret',

    // Apprise
    'apprisePassword',

    // Portica

    // Active Ant
    'activeant_password',

    // Aims360
    'aims360_password',
    'aims360_client_secret',

    // Cin7
    'cin7_application_key',

    // SapB1
    'sapb1_password',
    'sapb1_company_db',

    // Dynamics365
    // 'dynamics365_org_name',
    'dynamics365_tenant_id',
    'dynamics365_client_id',
    'dynamics365_client_secret',
    'dynamics365_companies_id',

    // Quickbook
    'quickbookPassword',

    // Convictional
    'convictionalPassword',
    'convictionalToken',

    //Warner
    'warner_password',
    'warner_port',

    //Quivo
    'quivo_password',
    'quivo_api_key',

    // CommerceHub
    'commercehub_client_id',
    'commercehub_client_secret',

    // LinkWeld
    'linkweld_connect_string',

    // myflex
    'myflex_url',
    'myflex_access_token',
    'myflex_refresh_token',

    //dynamicax
    'dynamicsAXPassword',

    // dynamicsfo
    'dynamicsfo_tenant_id',
    'dynamicsfo_client_id',
    'dynamicsfo_client_secret',
    'dynamicsfo_resource',
    'dynamicsfo_area_id',

    //SapB1 File Export
    'sapb1fileexport_password',
    'sapb1fileexport_port',

    //Channel Advisor File Import
    'cafileimport_password',
    // CartCom
    'cartcomId',
    'cartcomToken',
  ]
  async created() {
    const params = this.$route?.query
    if (!this.$route?.query || !Object.keys(params).every(v => ['company_id', 'company_name', 'api_key', 'code', 'state', 'scope', 'platform'].includes(v))) {
      this.$router.push('/login')
      return
    }
    if (this.$route.query.platform) {
      localStorage.setItem(MiraklLocalKey, this.$route.query.platform as string);
    }
    if (Object.keys(params).every(v => ['code', 'state', 'scope'].includes(v))) {
      this.isCallback = true
      await this.handleCallback()
    }
  }

  async handleCallback() {
    this.fullscreenLoading = true
    let payload: PayloadGetToken = {
      code: this.$route.query.code as string,
      state: this.$route.query.state as string
    }
    if (localStorage.getItem(MiraklLocalKey)) {
      payload = { ...payload, platform: localStorage.getItem(MiraklLocalKey) as string }
    }
    if (localStorage.getItem('mirakl-update-token') === 'true') {
      payload = { ...payload, updateToken: localStorage.getItem('mirakl-update-token') as string }
    }
    const siginInResponse: SiginInResponse = await axios.post('/mirakl/getToken', payload, { cancelToken: this.cancelTokenSource.token });
    if (!siginInResponse || !siginInResponse.tokenUser) {
      this.fullscreenLoading = false
      this.$router.push({ name: 'LoginPage' })
      return ElNotification({
        title: 'Error',
        message: siginInResponse.message,
        type: 'error',
      })
    }
    localStorage.removeItem(MiraklLocalKey)
    localStorage.removeItem('mirakl-update-token')

    if (siginInResponse.isConnectOtherExist) {
      this.fullscreenLoading = false
      await this.$store.dispatch('auth/setTokenUser', siginInResponse.tokenUser)
      return this.$router.push({ name: 'DashboardPage' })
    }
    this.tokenUser = siginInResponse.tokenUser
    this.accountId = siginInResponse.accountId
    let platform: any = siginInResponse?.platforms[0];
    this.dataConnect = {
      name: '',
    }
    this.options = platform?.options || []
    for (let item of platform.options) {
      this.dataConnect[item.key] = platform[item.key]
    }
    this.dataConnect.type = platform.type
    this.dataConnect.platform = platform._id
    this.dataConnect.price = platform.price
    this.dataConnect.discount = platform.discount
    this.dataConnect.name = siginInResponse.companyName || platform.name
    this.dataConnect.connectFrom = true
    this.dataConnect.connectTo = false
    this.dataConnect.image = platform.image

    this.showSiginInWithMirakl = false
    this.fullscreenLoading = false
  }

  async handleSave() {
    const refFrorm = this?.$refs['refForm'] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return

    await this.$store.dispatch('setLoading', true, { root: true })
    let res: any
    res = await axios.post('user/connect', this.dataConnect, {
      headers: {
        'token-user': this.tokenUser,
        account: localStorage.getItem('account')
      }
    })
    await this.$store.dispatch('setLoading', false, { root: true })
    if (res.status) {
      if (!this.skipedSave) {
        this.$message.success(res.message)
      }
      await this.$store.dispatch('auth/setTokenUser', this.tokenUser)
      return this.$router.push({ name: 'DashboardPage' })
    } else {
      this.$message.warning(res.message)
    }
  }

  async skipSave() {
    this.skipedSave = true
    for (let item of this.options) {
      this.dataConnect[item.key] = '0'
    }
    this.dataConnect.name = 'Default Name'
    this.handleSave()
  }

}
